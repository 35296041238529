import { Injectable } from '@angular/core';
import { AbstractClass, FormCell, TableCell } from '../abstract.class';
import { Store } from 'src/app/models/store';

@Injectable({providedIn: 'root'})
export class StoreClass extends AbstractClass{
  model: Store;
  modelName = 'stores';
  idName = 'id';
  langBase = 'admin.components.stores.';
  route = 'admin/store';
  formAccordion = [];
  formTabs = [];
  formCells: FormCell[] = [
    { name: 'name', label: 'name', cellType: 'text', order: 1, required: true, cols: 'col-12 col-md-6', disable: false },
    { name: 'mobile_phone', label: 'mobile_phone', cellType: 'number', order: 2, required: true, cols: 'col-12 col-md-6', disable: false },
    { name: 'address', label: 'address', cellType: 'text', order: 3, required: true, cols: 'col-12 col-md-6', disable: false },
    { name: 'latitude', label: 'latitude', cellType: 'text', order: 4, required: true, cols: 'col-12 col-md-6', disable: false },
    { name: 'longitude', label: 'longitude', cellType: 'text', order: 5, required: true, cols: 'col-12 col-md-6', disable: false },
    { name: 'active', label: 'active', cellType: 'boolean', order: 6, required: true, cols: 'col-12 col-md-6', disable: false },
    { name: 'image', label: 'image', cellType: 'images', order: 6, required: true, cols: 'col-12 col-md-6', disable: false }
  ];
  tableCells: TableCell[] = [
    { name: 'image', label: 'image', cellType: 'image', order: true, download: true },
    { name: 'name', label: 'name', cellType: 'text', order: true, download: true },
    { name: 'mobile_phone', label: 'mobile_phone', cellType: 'number', order: true, download: true },
    { name: 'address', label: 'address', cellType: 'text', order: true, download: true },
    { name: 'latitude', label: 'latitude', cellType: 'text', order: true, download: true },
    { name: 'longitude', label: 'longitude', cellType: 'text', order: true, download: true },
    { name: 'active', label: 'active', cellType: 'active-text', order: true, download: true },
    { name: 'created_at', label: 'created_at', cellType: 'date', order: true, download: true },
    { name: 'updated_at', label: 'updated_at', cellType: 'date', order: true, download: true }
  ];
  stringsToRemove = ['id'];
  fieldsUnrequiredUpdate = [];

  constructor()
  {
    super();
    this.model = {
      id: 0,
      name: '',
      mobile_phone: 0,
      address: '',
      latitude: '',
      longitude: '',
      image: null,
      active: true,
      created_at: new Date(),
      updated_at: new Date()
    };
  }
}
