import { createReducer, on } from '@ngrx/store';
import * as LanguageActions from '../actions/language.actions';

export const counterFeatureKey = 'language';

export interface AuthPartialState {
  readonly [counterFeatureKey]: Languages;
}

export const initialState: Languages = {
  languages: ['es'],
  selected: 'es'
};

export const languageReducer = createReducer(initialState,
  on(LanguageActions.setLanguages, (state, { language }) => {
    return {
      ...state,
      selected: language
    };
  })
);

export interface Languages {
  languages: string[];
  selected: string;
}
