import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UtilsService } from './utils.service';
import * as $ from 'jquery';

@Injectable()
export class CustomHttpService {

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService
  ) {}

  private getHeaderBaseJson(): HttpHeaders {
    let headers: HttpHeaders;
    if (this.utilsService.getToken() !== null) {
      headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('authorization', 'Bearer ' + this.utilsService.getToken());
    } else {
      headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('authorization', 'Bearer ' + this.utilsService.getToken());
    }
    return headers;
  }

  private getHeaderBaseFormData(): HttpHeaders {
    return new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.utilsService.getToken());
  }

  /**
   * Metodos de conexion con el API (GET, POST, PUT, GET-DATA)
   */
  // Metodo GET
  public httpGET(tempLoader: boolean, msg: boolean, path: string, params = {}): Promise<any> {
    if (tempLoader) {
      this.utilsService.loader(true);
    }
    return new Promise(
      resolve => {
        // Make the HTTP request:
        this.http.get(this.utilsService.getUrl() + path, {
          headers: this.getHeaderBaseJson(),
          params
        }).subscribe(
          // Successful responses call the first callback.
          (data: any) => {
            if (!data.success) {
              if (msg) { this.utilsService.showError(data); }
              if (tempLoader) { this.utilsService.loader(); }
              resolve(false);
            }  else {
              if (tempLoader) { this.utilsService.loader(); }
              resolve(data);
            }
          },
          // Errors will call this callback instead:
          err => {
            if (tempLoader) { this.utilsService.loader(); }
            if (msg) { this.utilsService.showError(err); }
            resolve(false);
          }
        );
      }
    );
  }

  // Metodo POST
  public httpPOST(tempLoader: boolean, msg: boolean, path: string, params: any = null, formData: any = null): Promise<any> {
    if (tempLoader) {
      this.utilsService.loader(true);
    }
    return new Promise(
      resolve => {
        const options = {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('authorization', 'Bearer ' + this.utilsService.getToken())
        };
        const url = this.utilsService.getUrl() + path;
        // Make the HTTP request:
        this.http.post(url, params, options).subscribe(
          // Successful responses call the first callback.
          (data: any) => {
            if (!data.success) {
              if (msg) { this.utilsService.showError(data); }
              if (tempLoader) { this.utilsService.loader(); }
              resolve(false);
            } else {
              if (tempLoader) { this.utilsService.loader(); }
              resolve(data);
            }
          },
          // Errors will call this callback instead:
          err => {
            if (tempLoader) { this.utilsService.loader(); }
            if (msg) { this.utilsService.showError(err); }
            resolve(false);
          }
        );
      }
    );
  }

  // Metodo PUT
  public httpPUT(tempLoader: boolean, msg: boolean, path: string, params: any = null, formData: any = null): Promise<any> {
    if (tempLoader) {
      this.utilsService.loader(true);
    }
    return new Promise(
      resolve => {
        let varHeader = this.getHeaderBaseJson();
        let varData = params;
        if (formData !== null) {
          varHeader = this.getHeaderBaseFormData();
          varData = formData;
        }
        // Make the HTTP request:
        this.http.put(
          this.utilsService.getUrl() + path,
          varData,
          { headers: varHeader }
        ).subscribe(
          // Successful responses call the first callback.
          (data: any) => {
            if (!data.success) {
              if (msg) { this.utilsService.showError(data); }
              if (tempLoader) { this.utilsService.loader(); }
              resolve(false);
            } else {
              if (tempLoader) { this.utilsService.loader(); }
              resolve(data);
            }
          },
          // Errors will call this callback instead:
          err => {
            if (tempLoader) { this.utilsService.loader(); }
            if (msg) { this.utilsService.showError(err); }
            resolve(false);
          }
        );
      }
    );
  }

  public httpDELETE(tempLoader: boolean, msg: boolean, path: string): Promise<any> {
    if (tempLoader) {
      this.utilsService.loader(true);
    }
    return new Promise(
      resolve => {
        // Make the HTTP request:
        this.http.delete(this.utilsService.getUrl() + path, {
          headers: this.getHeaderBaseJson(),
        }).subscribe(
          // Successful responses call the first callback.
          (data: any) => {
            if (!data.success) {
              if (msg) { this.utilsService.showError(data); }
              if (tempLoader) { this.utilsService.loader(); }
              resolve(false);
            } else {
              if (tempLoader) { this.utilsService.loader(); }
              resolve(data);
            }
          },
          // Errors will call this callback instead:
          err => {
            if (tempLoader) { this.utilsService.loader(); }
            if (msg) { this.utilsService.showError(err); }
            resolve(false);
          }
        );
      }
    );
  }
  /**
   * Fin Metodos de conexion con el API (GET, POST, PUT, GET-DATA)
   */
}
