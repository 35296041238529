import { languageReducer } from './reducers/language.reducer';
import { notificationReducer } from './reducers/notification.reducer';
import { menuReducer } from './reducers/menu.reducer';
import { loaderReducer } from './reducers/loader.reducer';

export const reducers = {
  language: languageReducer,
  notification: notificationReducer,
  menu: menuReducer,
  loader: loaderReducer
};
