import { Component, Input, OnInit } from '@angular/core';
import { AbstractService } from '@services/Abstract.service';
import { MyFile } from 'src/app/models/my-file';
import { Images } from '@constants/images';

@Component({
  selector: 'app-input-kml',
  templateUrl: './input-kml.component.html',
  styleUrls: ['./input-kml.component.scss']
})
export class InputKmlComponent implements OnInit {
  @Input() public serviceModel: AbstractService | null = null;
  @Input() label = '';
  @Input() cellType = '';
  @Input() name = '';
  @Input() required = false;
  @Input() multiple = false;
  filesImage: File[] = [];

  constructor(
      public images: Images
  ) { }

  ngOnInit(): void {
  }

  public onFilesAdded = (event: any) =>  {
    // console.log(event);
    const files = event.hasOwnProperty('addedFiles') ? event.addedFiles : event.target.files;
    this.filesImage.push(...files);
    this.selectFiles(files, this.name);
  }

  public onRemoveMultiple = (event: any) => {
    this.filesImage.splice(this.filesImage.indexOf(event), 1);
    if (this.serviceModel !== null) {
      this.serviceModel.modelClass.model[this.name] = this.multiple ? [] : null;
    }
    if (this.multiple) {
      this.filesImage.map((item: any) => {
        if (this.serviceModel !== null) {
          this.serviceModel.modelClass.model[this.name] = [...this.serviceModel.modelClass.model[this.name], this.getImageBase64([item])];
        }
      });
    }
  }

  public selectFiles = (images: any, name: string) => {
    if (this.serviceModel !== null) {
      if (this.multiple) {
        if (!this.serviceModel.modelClass.model.hasOwnProperty(name) || this.serviceModel.modelClass.model[name] === null) {
          this.serviceModel.modelClass.model[name] = [];
        }
        images.map((item: any) => {
          if (this.serviceModel !== null) {
            this.serviceModel.modelClass.model[name] = [...this.serviceModel.modelClass.model[name], this.getImageBase64([item])];
          }
        });
      } else {
        this.serviceModel.modelClass.model[name] = this.getImageBase64(images);
        // console.log(this.serviceModel.modelClass.model[name])
      }
    }
  }

  /**
   * Obtiene los datos de la imagen y la codifica en base64
   */
  private getImageBase64 = (image: any) => {
    const imageBase64: MyFile = {
      height: 0,
      timestamp: new Date(),
      uri: '',
      filename: '',
      data: ''
    };
    const file: File = image[0];
    const myDate = new Date();
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      imageBase64.height = file.size;
      imageBase64.timestamp = new Date('2019-01-06T17:16:40');
      imageBase64.uri = file.name;
      imageBase64.filename = file.name;
      const result = myReader.result ?? '';
      imageBase64.data = result.toString().split(',')[1] ?? '';
    };
    myReader.readAsDataURL(file);
    return imageBase64;
  }
}
