import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslatePipe } from '@pipes/translate';
import { GetJsonValue } from '@pipes/getJsonValue';
/** Copmponents */

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    TranslatePipe,
    GetJsonValue
  ],
  exports: [
    TranslatePipe,
    GetJsonValue
  ],
  providers: [
    GetJsonValue,
    TranslatePipe,
    DatePipe
  ]
})
export class PipesModule { }
