import { Injectable } from '@angular/core';
import { CustomHttpService } from '../generals/customHttp.service';
import { AbstractService } from '../Abstract.service';
import { RoleClass } from '@classes/admin/role.class';

@Injectable({ providedIn: 'root' })
export class RoleService extends AbstractService {
  public version = '';
  constructor(
    public customHttpService: CustomHttpService,
  ) {
    super(customHttpService, new RoleClass());
  }
}

