import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';
import * as thisActions from '../actions/loader.actions';
import * as thisReducer from '../reducers/loader.reducer';
import * as thisSelectors from '../selectors/loader.selectors';
import { Loadable } from '@models/loadable';

@Injectable()
export class LoaderFacade {

  loaderState$: Observable<Loadable> = this.store.pipe(
    select(thisSelectors.selectLoaderState)
  );

  constructor(
    private store: Store<thisReducer.LoaderPartialState>
  ) {}

  private dispatch = (action: Action) => {
    this.store.dispatch(action);
  }

  public showLoader = () => {
    this.dispatch(thisActions.showLoader());
  }

  public closeLoader = () => {
    this.dispatch(thisActions.closeLoader());
  }
}
