import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractInputSelect } from '@components/field_form/select-inputs/abstract-input-select';
import { AbstractService } from '@services/Abstract.service';
import { StoreService } from '@services/cms/store.service';

@Component({
  selector: 'app-input-select-slider-type',
  templateUrl: './input-select-slider-type.component.html',
  styleUrls: ['./input-select-slider-type.component.scss']
})
export class InputSelectSliderTypeComponent extends AbstractInputSelect implements OnInit {
  @Input() public serviceModel: AbstractService | null = null;
  @Input() public label = 'store';
  @Input() public name = '';
  @Input() public required = false;
  @Input() public multiple = false;
  reloadData = true;
  list: any[] = [
    { id: 'Info', name: 'Informativa' },
    { id: 'Url', name: 'Url' },
    { id: 'Product', name: 'Producto' }
  ];
  selectTemporal: any[] = [];
  // Events
  @Output() changeAction = new EventEmitter<any>();
  @Output() loadAction = new EventEmitter<any>();

  constructor() {
    super(null);
  }

  ngOnInit(): void {}

  public change = () => {
    this.changeAction.emit();
  }
}
