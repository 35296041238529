<div class="tyc">
  <h1>POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES</h1>
  <h1 class="mb-5">ROOTS FOR SUSTAINABILITY</h1>
  <h1>CAPÍTULO PRIMERO</h1>
  <h1 class="mb-5">DISPOSICIONES GENERALES</h1>
  <p class="subtitle">
    <span>1.1</span><b>Responsable del Tratamiento. ROOTS FOR SUSTAINABILITY</b>, en adelante <b>R4S</b>, actuando como
    responsable del Tratamiento de la información personal, y, en cumplimiento de la Ley de
    Protección de Datos Personales, 1581 de 2012, y sus decretos reglamentarios, se identifica a
    través de los siguientes datos:
  </p>
  <table class="mt-4 mb-4">
    <tr>
      <td>Razón Social</td>
      <td>ROOTS FOR SUSTAINABILITY, SL.</td>
    </tr>
    <tr>
      <td>NIF</td>
      <td>B65479339</td>
    </tr>
    <tr>
      <td>Dirección</td>
      <td>Pallars 147, 3ª planta - 08018 Barcelona – España</td>
    </tr>
    <tr>
      <td>Correo electrónico</td>
      <td>info@r4sgroup.com.</td>
    </tr>
    <tr>
      <td>Teléfono</td>
      <td>699960900</td>
    </tr>
    <tr>
      <td>Página web</td>
      <td>https://r4sgroup.com/</td>
    </tr>
  </table>
  <p class="subtitle">
    <span>1.2</span><b>Definiciones</b>
  </p>
  A continuación, se relaciona las siguientes definiciones para una comprensión adecuada de la
  presente política:
  <p class="list mt-4"><span>a.</span>Autorización: Consentimiento previo, expreso e informado del Titular para llevar a cabo el
    Tratamiento de datos personales;</p>
  <p class="list"><span>b.</span>Base de Datos: Conjunto organizado de datos personales que sea objeto de Tratamiento;</p>
  <p class="list"><span>c.</span>Dato personal: Cualquier información vinculada o que pueda asociarse a una o varias
    personas naturales determinadas o determinables;</p>
  <p class="list"><span>d.</span>Encargado del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma
    o en asocio con otros, realice el Tratamiento de datos personales por cuenta del Responsable
    del Tratamiento;</p>
  <p class="list"><span>e.</span>Responsable del Tratamiento: Persona natural o jurídica, pública o privada, que por sí
    misma o en asocio con otros, decida sobre la base de datos y/o el Tratamiento de los datos;</p>
  <p class="list"><span>f.</span>Titular: Persona natural cuyos datos personales sean objeto de Tratamiento;</p>
  <p class="list"><span>g.</span>Tratamiento: Cualquier operación o conjunto de operaciones sobre datos personales, tales
    como la recolección, almacenamiento, uso, circulación o supresión;</p>
  <p class="list"><span>h.</span>Usuarios: Los titulares de información que tienen relación con R4S debido a que ha
    descargado la App Recicla up, creado y/o registrado un usuario que los vincula con la
    prestación del servicio de recolección de material reciclable.</p>
  <p class="list"><span>i.</span>Ciudadano: Usuario que reside en una vivienda que hace parte de la ruta registrada en la
    app y realizada por uno de los recicladores.</p>
  <p class="list"><span>j.</span>Tendero: Usuario que administra la superficie donde se redime y entregan los productos de
    compensación por el reciclaje entregado por los ciudadanos.</p>
  <p class="list"><span>k.</span>Tienda: las superficies que ofrecen y/o facilitan la redención y entrega de productos de
    compensación a los usuarios por entregar su reciclaje.</p>
  <p class="list"><span>l.</span>Centro de Acopio: Usuario de la Asociación de Reciladores que recibe y registra de manera
    clasificada el material recolectado por los recicladores en la ruta de recolección.</p>
  <p class="list"><span>m.</span>Ruta de Recolección: Espacio georefeciado por donde transita el reciclador asignado, para
    hacer la recolección del material reciclado por los ciudadanos.</p>
  <p class="list"><span>n.</span>Reciclador: persona asociada que asigna el centro de acopio para realizar la ruta de
    recolección.</p>
  <p class="list"><span>o.</span>Asociación de recicladores: es una organización prestadora del servicio público de aseo en
    el componente de aprovechamiento, en proceso de fortalecimiento empresarial.</p>
  <p class="list"><span>p.</span>Administrador: R4S es el administrador y propietario de la App recicla Up.</p>
  <p class="list"><span>q.</span>Cookies: Una "cookie" es un pequeño elemento de datos que un sitio web envía a su
    navegador, quien lo almacena en su disco rígido de manera que Samsung pueda reconocerlo
    cuando el titular vuelve a visitar el sitio web.</p>
  <p class="subtitle mt-4">
    <span>1.3</span><b>Objetivo y alcance de la Política.</b>
  </p>
  La Política de Tratamiento de Datos Personales tiene como propósito establecer los criterios bajo los
  cuales se realiza el tratamiento de la información personal que reposa en las bases de datos y en
  archivos físicos y electrónicos de la App Recicla Up en propiedad de R4S. La Política de Tratamiento
  de Datos Personales, establece los lineamientos que R4S lleva a cabo para el tratamiento de los datos
  personales, mecanismos para ejercicio del derecho de hábeas data, así como las finalidades,
  medidas de seguridad, y otros aspectos relacionados con la protección de la información personal.
  <p class="subtitle mt-4">
    <span>1.4</span><b>Terceros a quienes va dirigida la Política.</b>
  </p>
  La presente Política de Tratamiento de Datos Personales, está dirigida a:
  <p class="list mt-4"><span>a.</span>Usuarios de la App Recicla Up propiedad de R4S</p>
  <p class="list"><span>b.</span>Empleados y Colaboradores</p>
  <p class="list"><span>c.</span>Patrocinadores y/o Proveedores y Aliado</p>
  <p class="list"><span>d.</span>Asociaciones de recicladores vinculadas.</p>
  <p class="list"><span>e.</span>Agencias de Publicidad y/o mercadeo</p>
  <p class="list"><span>f.</span>Centros de Servicios Técnicos</p>
  <p class="list"><span>g.</span>Encargados de la Información</p>
  <p class="list"><span>h.</span>Cualquier titular de la información, ya sea actuando a nombre propio, o como representante
    legal que, con ocasión, a las actividades que realice, se encuentre vinculado con la App Recicla
    Up propiedad de R4S, se requiera de su información personal para el desarrollo de las mismas.</p>
  <p class="subtitle mt-4">
    <span>1.5</span><b>Cumplimiento Normativo del Régimen de Protección de Datos Personales.</b>
  </p>
  La presente Política de Tratamiento de Datos Personales, da cumplimiento al Régimen de Protección
  de Datos Personales en Colombia, en especial, el artículo 15 y 20 de la Constitución Nacional, la Ley
  1581 de 2012, el Capítulo 25 del Decreto 1074 de 2015, Sentencia C-748 de 2011, Circulares de la
  Superintendencia de Industria y Comercio y demás normas que la modifiquen, complementen o
  adicionen.
  <p class="subtitle mt-4">
    <span>1.6</span><b>Cumplimiento de los principios para el Tratamiento de Datos Personales.</b>
  </p>
  R4S garantiza los principios de legalidad, finalidad, libertad, veracidad o calidad, transparencia,
  acceso y circulación restringida, seguridad y confidencialidad sobre los datos que reposan en las
  bases de datos que se encuentran en posesión de R4S.
  <p class="subtitle mt-4">
    <span>1.7</span><b>Vinculatoriedad de la Política de Tratamiento de Datos Personales.</b>
  </p>
  Los terceros de la información a quien se encuentra dirigida esta política, deben cumplir con los
  lineamientos establecidos en el presente instrumento, así como cumplir con el manual interno
  políticas y procedimientos relativas a la protección de datos personales, dependiendo del tipo de
  vínculo que tenga con R4S. Cualquier titular de datos personales que tenga alguna relación con R4S,
  lo vincula con la presente política, cuando ha brindado su autorización para el tratamiento de sus
  datos personales.

  <h1 class="mt-5">CAPÍTULO SEGUNDO</h1>
  <h1 class="mb-4">FINALIDADES DEL TRATAMIENTO DE LOS DATOS PERSONALES</h1>
  <p class="subtitle mt-4">
    <span>2.1</span><b>Bases de Datos y Archivos con información Personal.</b>
  </p>
  <b>La App Recicla Up como propiedad de R4S</b>, tiene diferentes tipos de bases datos y archivos con
  información personal, tanto físicos, como digitales, y se encuentran agrupados en diferentes
  categorías, como pueden ser, sin limitarse a ellas: Usuarios, Tenderos, Centro de Acopios,
  Patrocinadores, Recicladores, Asociaciones…
  <p class="subtitle mt-4">
    <span>2.2</span><b>Finalidades de la Recolección de la Información Personal.</b>
  </p>
  Las finalidades de la recolección de información personal por parte de R4S son las siguientes:
  <p class="subtitle mt-4 sub">
    <span>2.2.1</span><b>Finalidades relativas a la gestión de Usuarios</b>
  </p>
  <p class="list mt-4"><span>✓</span>Contactar a los usuarios, por cualquier medio conocido o por conocer, para brindarle
    información sobre nuestros servicios, así como solicitarle información sobre la experiencia
    con la App Recicla Up.</p>
  <p class="list"><span>✓</span>Prestación de servicios propios de la aplicación Recicla Up.</p>
  <p class="list"><span>✓</span>Prestación del servicio, relativo a los servicios post vinculación con la App Recicla p, como
    son PQR.</p>
  <p class="list"><span>✓</span>Prestación de servicios nuevos que podría ofrecer Recicla Up para la mejora de la experiencia
    del usuario, así como servicio al cliente y actividades para la difusión de información
    pertinente e importante para los usuarios de la App.</p>
  <p class="list"><span>✓</span>Gestión de tareas básicas de administración; informar por cualquiera de los medios
    proporcionados al momento del suministro de la información, las promociones, novedades,
    productos y servicios actuales y futuros relacionados con eventos, concursos, actividades,
    entrega de premios, beneficios y productos adquiridos; responder a solicitudes específicas
    de los usuarios y otras finalidades directa o indirectamente relacionadas con la actividad
    propia de Recicla Up, novedades, productos y servicios promovidos directamente por los
    aliados.</p>
  <p class="list"><span>✓</span>Encuestas de opinión, Prospección comercial, Publicidad propia, Segmentación de
    mercados, Ofrecimiento de productos y servicios por cualquier medio conocido o por
    conocer.</p>
  <p class="list"><span>✓</span>Desarrollo de actividades de mercadeo, realización de eventos relativos a la App Recicla Up,
    eventualmente se captura imágenes y videos de personas, así mismo, se recolecta
    información para actividades que realiza sin ánimo de lucro y con fines sociales.</p>
  <p class="list"><span>✓</span>Conocer cuantas veces visitas la App Recicla Up, para poder entregar información que
    considere que puede llegar a ser de su interés, según las preferencias y comportamientos
    que el usuario tiene o ha tenido dentro de la App. A través de las cookies, se podrá optimizar
    la publicidad que el usuario ve en la App o recibe mediante correros electrónicos, mensajes
    push o por medio de pautas digitales de diferentes proveedores de la app. En este caso, R4S.
    puede guardar la navegación de los usuarios finales para mejorar la experiencia del usuario,
    y saber sobre los productos y servicios que mostró interés en el momento de la visita.</p>
  <p class="list"><span>✓</span>Difusión de eventos sociales y comerciales realizados por R4S y sus aliados, donde R4S,
    puede llegar a capturar imágenes a través de cámara y de video de los titulares, y difundirlos
    a través de sus redes sociales y plataformas web administradas por R4S, o en algún comercial
    o publicidad en cualquier tipo de formato.</p>
  <p class="subtitle mt-4 sub">
    <span>2.2.2</span><b>Finalidades comunes a todos los titulares de la información</b>
  </p>
  <p class="list"><span>✓</span>Gestión de estadísticas internas, atención al ciudadano/cliente (Gestión PQR) Fidelización de
    clientes, Envío de comunicaciones.</p>
  <p class="list"><span>✓</span>Procedimientos administrativos, Administración de Sistemas de información, gestión de
    claves, administración de usuarios, Desarrollo operativo.</p>
  <p class="subtitle mt-4">
    <span>2.3</span><b>Tipos de Datos Personales que recolecta R4S.</b>
  </p>
  <b>La App Recicla UP propiedad de R4S</b> recolecta datos personales de carácter público, semiprivado,
  privado y sensible. De acuerdo a la clasificación del Registro Nacional de Bases de Datos Personales,
  las categorías de datos que tiene la App Recicla up, en sus bases de datos, son las siguientes:
  <p class="list mt-4"><span>✓</span>Datos generales de identificación.</p>
  <p class="list"><span>✓</span>Datos específicos de identificación.</p>
  <p class="list"><span>✓</span>Datos de ubicación relacionados con la actividad privada (lugar de residencia).</p>
  <p class="list"><span>✓</span>Datos de ubicación relacionados con la actividad comercial.</p>
  <p class="list"><span>✓</span>Datos personales de acceso a sistemas de información: usuarios, IP, claves, perfiles, etc.</p>
  <p class="list"><span>✓</span>Gustos e intereses particulares</p>
  <p class="subtitle mt-4">
    <span>2.4</span><b>Recolección de los datos personales.</b>
  </p>
  La App Recicla Up propiedad de R4S recolecta información personal a través de diferentes
  actividades relacionadas con su objeto social. Los instrumentos que utiliza R4S para recolección de
  la información, cuentan con los requisitos establecidos en la normatividad de protección de datos
  personales, y obedecen a los principios de libertad y finalidad, por lo que en cada uno de ellos, se
  encuentra incorporada la autorización para el tratamiento de los datos personales.zm
  <p class="subtitle mt-4">
    <span>2.5</span><b>Almacenamiento.</b>
  </p>
  El almacenamiento de la información contenida en las bases de datos personales, se encuentra en
  los servidores propios dentro y fuera del país, y en servidores externos a cargo de terceros, y cuenta
  con todas las medidas de seguridad física, técnicas y administrativas, y con controles de acceso a la
  información, garantizando el principio de seguridad, acceso y circulación restringida y
  confidencialidad. Por lo anterior, cuando usted nos suministra la autorización para el tratamiento de
  los datos personales, también autoriza que su información pueda ser transmitida a proveedores que
  pueden estar en Colombia, o fuera del país, que se encuentran ubicados en la lista de países seguros,
  emitida por la Superintendencia de Industria y Comercio, para finalidades de almacenamiento de la
  información o cuenta con los controles requeridos por la ley para garantizar la seguridad de los datos.
  <p class="subtitle mt-4">
    <span>2.6</span><b>Circulación.</b>
  </p>
  R4S. podrá comunicar los datos personales de los usuarios a terceros prestadores de servicios de
  R4S. A estas empresas se les proporcionan solo los datos personales necesarios para la prestación
  del servicio en cuestión, las cuales se comprometen a tratar dichos datos personales única y
  exclusivamente para el cumplimiento de los fines arriba mencionados.<br><br>
  R4S en su compromiso con la privacidad y protección de datos del Usuario, elegirá únicamente
  prestadores de servicio que ofrezcan las garantías suficientes para aplicar medidas técnicas y
  organizativas apropiadas, de manera que el tratamiento sea conforme la legislación aplicable en
  materia de protección de datos y garanticen la protección de los derechos del Usuario.
  <p class="subtitle mt-4">
    <span>2.7</span><b>Conservación y Supresión de la información.</b>
  </p>
  La información personal que la App Recicla up propiedad de R4S solicite, puede ser conservada con
  ocasión al cumplimiento de aspectos: legales, contractuales, tributarios, y en algunas ocasiones con
  fines de auditoría, y permanecerá almacenada de acuerdo a los tiempos máximos establecidos en la
  legislación colombiana para su retención.<br><br>
  La supresión de la información personal, cuyas finales no son obligatorias por ley, se realizará una
  vez se ha cumplido la finalidad, conforme a las autorizaciones, contratos y/o acuerdos que el titular
  de la información previamente haya pactado con R4S.
  <p class="subtitle mt-4">
    <span>2.8</span><b>Autorización para el tratamiento de datos personales.</b>
  </p>
  Cuando usted autoriza el tratamiento de sus datos personales a la App Recicla up propiedad de R4S,
  tiene conocimiento que dicha autorización se extiende a lo contemplado en la presente política, por
  lo anterior, usted acepta, y entiende que la presente política, hace parte integral de la autorización
  que usted nos suministra. Por lo anterior, hemos puestos a disposición en cada uno de nuestros
  canales, la política de tratamiento de datos y/o el aviso de privacidad, para que conozca el alcance
  del tratamiento de sus datos personales.
  <p class="subtitle mt-4">
    <span>2.9</span><b>Política de Privacidad Global de ROOTS FOR SUSTAINABILITY, S.L.</b>
  </p>
  La política de privacidad de R4S, se encuentra disponible en el siguiente
  enlace: <a href="https://r4sgroup.com/es/politica-privacidad/" target="_blank">https://r4sgroup.com/es/politica-privacidad/</a> y hace parte integral de la presente política de
  tratamiento de datos personales relativo exclusivamente a los usuarios de la App Recicla up de
  propiedad de R4S.<br><br>
  El usuario, debe tener en cuenta que cuando se trate de información personal que ha suministrado
  en virtud de un servicio ofrecido directamente por R4S, las condiciones relativas al tratamiento de
  sus datos personales, se regirá por la política de privacidad de R4S.<br><br>
  Cuando se trate de productos y/o servicios generados por App Recicla up de R4S., se aplicará la
  presente política de Tratamiento de Datos Personales. En ningún momento la presente política de
  tratamiento de datos personales, sustituye o prevalece sobre la Política de Privacidad de R4S, y en
  todo caso, se interpretarán de manera complementaria.
  <p class="subtitle mt-4">
    <span>2.10</span><b>Transmisión y Transferencia Nacional e Internacional de Datos Personales.</b>
  </p>
  R4S para el cumplimiento de las finalidades descritas en la presente política, transmite y/o transfiere
  sus datos personales dentro y fuera del territorio colombiano, garantizando en todo momento las
  medidas de seguridad adecuadas para garantizar la seguridad de los datos personales al momento
  de la transmisión y/o transferencia. Por lo anterior, cuando usted nos autoriza el tratamiento de sus
  datos personales, autoriza a R4S, nos autoriza a:
  <p class="list mt-4"><span>i)</span>transmitir sus datos personales a encargados ubicados en el territorio nacional o fuera de
    este, para realizar el tratamiento de la información personal, conforme a las finalidades
    establecidas en la presente política y a</p>
  <p class="list mb-4"><span>ii)</span>transferir sus datos personales a responsables que ejercerán sus roles de acuerdo a las
    finalidades establecidas en la presente política.</p>
  Cuando se trate de transmisión y transferencia nacional o internacional de datos personales, R4S
  tiene establecidos contratos de transmisión o de transferencia de datos personales y acuerdos
  de confidencialidad con dichos terceros, según corresponda, así como, en algunos casos, la
  autorización de dicha transmisión o transferencia, por parte de los titulares de la información.
  <p class="subtitle mt-4">
    <span>2.11</span><b>Medidas de Protección.</b>
  </p>
  Samsung tiene adoptado medidas técnicas, jurídicas, humanas y administrativas necesarias para
  procurar la seguridad de los datos de carácter personal protegiendo la confidencialidad, integridad,
  uso, acceso no autorizado y/o fraudulento. Asimismo, internamente Samsung ha implementado
  protocolos de seguridad de obligatorio cumplimiento para todo el personal con acceso a datos de
  carácter personal y a los sistemas de información. Las políticas internas de seguridad bajo las cuales
  se conserva la información del titular para impedir su adulteración, pérdida, consulta, uso o acceso
  no autorizado o fraudulento, son las siguientes:
  <p class="list mt-4"><span>✓</span>Políticas de Tratamiento de Datos Personales.</p>
  <p class="list"><span>✓</span>Procedimientos para la validación de datos de entrada y procesamiento de la información
    personal.</p>
  <p class="list"><span>✓</span>Controles de seguridad de información para la validación de datos de salida</p>
  <p class="list mb-4"><span>✓</span>Controles para la disposición final de la información.</p>
  <h1 class="mt-5">CAPÍTULO TERCERO</h1>
  <h1 class="mb-4">DERECHOS QUE TIENEN LOS TITULARES DE LOS DATOS PERSONALES</h1>
  <p class="subtitle mt-4">
    <span>3.1</span><b>Derechos que le asiste como titular del dato.</b>
  </p>
  El Derecho Fundamental al Habeas Data, faculta al titular del dato solicitar el acceso, actualización,
  rectificación y supresión de sus datos personales que se encuentran en posesión de un tercero, a su
  vez, puede revocar la autorización que ha otorgado para el tratamiento. Si un titular de datos
  personales considera que la App Recicla Up tiene acceso a sus datos personales, esta persona puede
  en todo momento solicitar la consulta de sus datos, o si considera que la App esta haciendo mal uso
  de sus datos, puede realizar la respectiva reclamación.<br>
  El titular está facultado para solicitar:
  <p class="list"><span>a.</span>Actualización de sus datos personales en caso de encontrarse fraccionados, incompletos,
    entre otros.</p>
  <p class="list"><span>b.</span>Rectificación y/o corrección de sus datos personales en caso de que se encuentren errados,
    parciales o induzcan a error.</p>
  <p class="list"><span>c.</span>Supresión de sus datos personales de las bases de datos. Se seguirá conservando la
    información para los fines determinados por la ley.</p>
  <p class="list"><span>d.</span>Revocación de la autorización del tratamiento de sus datos personales, siempre y cuando no
    genere incumplimiento por parte de Samsung con otras obligaciones legales, relativas a la
    permanencia del dato.</p>
  <p class="subtitle mt-4">
    <span>3.2</span><b>Información que debe acreditar el titular del dato.</b>
  </p>
  Para efectos de consulta y reclamos, el titular del dato debe acreditar sus datos de identificación
  tales como:
  <p class="list mt-4"><span>✓</span>Nombres completos y apellidos</p>
  <p class="list"><span>✓</span>Tipo y número de identificación</p>
  <p class="list"><span>✓</span>Teléfono de contacto</p>
  <p class="list"><span>✓</span>Correo electrónico</p>
  <p class="list mb-4"><span>✓</span>Brindar la información necesaria para tramitar su solicitud.</p>
  <p class="subtitle mt-4">
    <span>3.3</span><b>Canales habilitados para el ejercicio de los Derechos de Habeas Data.</b>
  </p>

  <p class="list mt-4"><span>A.</span><b>CANALES ELECTRÓNICOS</b></p>
  <p class="list mb-4"><span>B.</span><b>CANALES TELEFONICOS</b></p>
  Estos son los únicos canales que la App Recicla up de R4S tiene habilitados para las consultas y
  reclamos por protección de datos personales, por lo tanto, el titular deberá tenerlos presente.
  <h1 class="mt-5">CAPÍTULO CUARTO</h1>
  <h1 class="mb-4">DISPOSICIONES FINALES</h1>
  <p class="subtitle mt-4">
    <span>4.1</span><b>Medidas permanentes.</b>
  </p>
  En el tratamiento de datos personales, R4S de manera permanente, verificará en la App Recicla Up
  los procesos, protocolos, procedimientos y políticas, que se garantice el derecho de hábeas data a
  los titulares de la información y que se obtenga con los requisitos de ley, la autorización del titular
  para el tratamiento de los datos personales.
</div>
