import { Injectable } from '@angular/core';
import { CustomHttpService } from '../generals/customHttp.service';
import { AbstractService } from '../Abstract.service';
import { Store } from 'src/app/models/store';
import { StoreClass } from '@classes/admin/store.class';

@Injectable({ providedIn: 'root' })
export class StoreService extends AbstractService {
  public roles: any;
  public country: Store | undefined;
  public version = '';
  constructor(
    public customHttpService: CustomHttpService,
  ) {
    super(customHttpService, new StoreClass());
  }

  /** Función que inserta el Modelo */
  public create = () => {
    const self = this;
    const url = self.version + self.base;
    if (this.modelClass.model['image'] !== null && this.modelClass.model['image'].filename === undefined) {
      this.modelClass.model['image'] = '';
    }
    return new Promise(
        resolve => {
          self.customHttpService.httpPOST(
              true,
              true,
              url + this.modelClass.modelName,
              self.modelClass.getJsonBase()
          ).then(
              (response) => {
                if (response === false) { resolve(response); }
                resolve(true);
              }
          );
        }
    );
  }

  /** Funcion que actualiza el Modelo */
  async update(id: number): Promise<any> {
    const self = this;
    const url = self.version + self.base;
    if (this.modelClass.model['image'] !== null && this.modelClass.model['image'].filename === undefined) {
      this.modelClass.model['image'] = '';
    }
    return await new Promise(
        resolve => {
          self.customHttpService.httpPUT(
              true,
              true,
              url + this.modelClass.modelName + '/' + id,
              this.modelClass.getJsonUpdate()
          ).then(
              (response) => {
                if (response === false) { resolve(response); }
                resolve(true);
              }
          );
        }
    );
  }
}

