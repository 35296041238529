import { Injectable } from '@angular/core';
import { CustomHttpService } from '../generals/customHttp.service';
import { AbstractService } from '../Abstract.service';
import { InstitutionClass } from '@classes/admin/institution.class';
import {AbstractClass} from '@classes/abstract.class';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  public version = '';
  public base = 'api/cms/dashboard/';

  constructor(
      public customHttpService: CustomHttpService,
  ) {
  }

  public getOverview(): Promise<any>{
    const self = this;
    const url = self.version + self.base + 'overview';
    return new Promise(
        resolve => {
          self.customHttpService.httpGET(true, true, url).then(
              (response) => {
                if (response === false) { resolve(response); }
                resolve(response);
              }
          );
        }
    );
  }
}

