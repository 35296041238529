import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';
import * as thisActions from '../actions/language.actions';
import * as thisReducer from '../reducers/language.reducer';
import * as thisSelectors from '../selectors/language.selectors';
import { Languages } from '../reducers/language.reducer';

@Injectable()
export class LanguageFacade {

  languageState$: Observable<Languages> = this.store.pipe(
    select(thisSelectors.selectLanguageState)
  );

  constructor(
    private store: Store<thisReducer.AuthPartialState>
  ) {}

  private dispatch = (action: Action) => {
    this.store.dispatch(action);
  }

  public setLanguage = (language: string) => {
    this.dispatch(thisActions.setLanguages({ language }));
  }
}
