<div class="logo {{ menuLarge ? 'menu-large' : 'menu-short' }}">
  <a class="simple-text" [routerLink]="['/admin/home']" *ngIf="menuLarge">{{ 'title' | translatePipe }}</a>
  <div class="icon-menu" *ngIf="!menuLarge">
    <mat-icon class="material-icons">menu</mat-icon>
  </div>
  <a *ngIf="!isMobileMenu() && menuLarge && menuPermanent" (click)="changePermanent(false)"><i class="material-icons logo-icon-close">close</i></a>
  <a *ngIf="!isMobileMenu() && menuLarge && !menuPermanent" (click)="changePermanent(true)"><i class="material-icons logo-icon-close">menu</i></a>
  <a *ngIf="isMobileMenu() && menuLarge" (click)="closeMenu()"><i class="material-icons logo-icon-close">close</i></a>
</div>
<div class="sidebar-wrapper">
  <ul class="nav {{ menuLarge ? 'menu-large' : 'menu-short' }}">
    <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="nav-item">
      <a class="nav-link" [routerLink]="[menuItem.path]" *ngIf="menuItem.submenu.length == 0">
        <img src="{{ menuItem.icon }}" alt="{{ menuItem.title | translatePipe }}">
        <p (click)="openSubmenu(menuItem)">{{ menuItem.title | translatePipe }}</p>
      </a>
      <div class="nav-link" *ngIf="menuItem.submenu.length > 0">
        <img src="{{ menuItem.icon }}" alt="{{ menuItem.title | translatePipe }}">
        <p (click)="openSubmenu(menuItem)">{{ menuItem.title | translatePipe }}</p>
      </div>
      <ul *ngIf="menuItem.submenu.length > 0" class="sub-nav {{ menuItem.open ? 'open' : 'close' }}">
        <li routerLinkActive="active" *ngFor="let submenu of menuItem.submenu" class="sub-nav-item">
          <a class="sub-nav-link" [routerLink]="[submenu.path]">
            <p>{{ submenu.title | translatePipe }}</p>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</div>
