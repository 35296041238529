import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Images } from '@constants/images';
import { AbstractService } from '@services/Abstract.service';

@Component({
  selector: 'app-form-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss']
})
export class FormBasicComponent implements OnInit {
  public imageClose = Images.iconClosePopup;
  @Input() title = '';
  @Input() service: AbstractService | undefined;
  @Output() closeAction = new EventEmitter<any>();
  @Output() saveAction = new EventEmitter<any>();
  public error = false;
  public errors: any[] = [];

  constructor() { }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    // console.log(this.service?.modelClass);
  }

  public save = () => {
    this.error = false;
    this.errors = [];
    this.service?.modelClass.getInputsForm().filter(i => i.required).map((value, id) => {
      if (this.service?.modelClass.model[value.name] === undefined || this.service?.modelClass.model[value.name] === null
        || this.service?.modelClass.model[value.name] === '' || this.service?.modelClass.model[value.name].length === 0) {
        this.error = true;
        this.errors.push(value.label);
      }
    });
    if (!this.error) {
      this.saveAction.emit();
    }
  }

  public close = () => {
    this.closeAction.emit();
  }
}
