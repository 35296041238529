import { createReducer, on } from '@ngrx/store';
import * as MenuActions from '../actions/menu.actions';

export const menuFeatureKey = 'menu';

export interface MenuPartialState {
  readonly [menuFeatureKey]: Menu;
}

export const initialState: Menu = {
  open: false,
  permanent: false
};

export const menuReducer = createReducer(initialState,
  on(MenuActions.changeState, (state) => {
    return {
      ...state,
      open: !state.open
    };
  }),
  on(MenuActions.setOpen, (state, { open }) => {
    return {
      ...state,
      open
    };
  }),
  on(MenuActions.setPermanent, (state, { permanent }) => {
    return {
      ...state,
      permanent
    };
  })
);

export interface Menu {
  open: boolean;
  permanent: boolean;
}
