import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatAccordion} from '@angular/material/expansion';
import { Images } from '@constants/images';
import {Materials} from 'src/app/models/materials';

@Component({
  selector: 'app-accordion-materials',
  templateUrl: './accordion-materials.component.html',
  styleUrls: ['./accordion-materials.component.scss'],
  providers: [
  ]
})

export class AccordionMaterialsComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion | undefined;
  @Input() public materialDates: {
    date: Date;
    materials: Materials
  }[] = [];
  public arrowRight = Images.arrowRight;
  @Output() itemSeleccionado = new EventEmitter();

  constructor() {}
  ngOnInit(): void {
  }
}
