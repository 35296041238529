import { Injectable } from '@angular/core';

@Injectable()
export class Images {
  // Menu Images
  static iconHome = './assets/images/re_icon_home.svg';
  static iconMarketData = './assets/images/re_icon_market_data.svg';
  static iconUsers = './assets/images/re_icon_users.svg';
  static iconDeviceSettings = './assets/images/re_icon_device_settings.svg';
  static iconGeneralSettings = './assets/images/re_icon_general_settings.svg';
  static iconStore = './assets/images/re_icon_store.svg';
  static iconStoreExecution = './assets/images/re_icon_store_execution.svg';
  static iconTrash = './assets/images/re_icon_trash.svg';
  static iconFilter = './assets/images/re_icon_filter.svg';
  static iconPlusCircle = './assets/images/re_icon_plus_circle.svg';
  static iconEdit = './assets/images/re_icon_edit.svg';
  static iconSearch = './assets/images/re_icon_search.svg';
  static iconClosePopup = './assets/images/re_icon_close_popup.svg';
  static iconArrowBottom = './assets/images/re_icon_arrow_bottom.svg';
  static arrowLeft = './assets/images/next_left.svg';
  static arrowRight = './assets/images/next_right.svg';
  static iconPaginatorLast = './assets/images/re_icon_paginator_last.svg';
  static iconPaginatorFirst = './assets/images/re_icon_paginator_first.svg';
  // Another Images
  loader = './assets/images/re_loader.svg';
  logo = './assets/images/re_logo.svg';
  iconInformation = './assets/images/re_icon_information.svg';
  iconAlert = './assets/images/re_icon_alert.svg';
  iconSuccess = './assets/images/re_icon_success.svg';
  iconError = './assets/images/re_icon_error.svg';
  iconFilterNonStatic = './assets/images/re_icon_filter.svg';
  iconPlusCircle = './assets/images/re_icon_plus_circle.svg';
  // List Images
  emptyImage = './assets/images/re_icon_error.svg';
  iconEdit = './assets/images/re_icon_error.svg';
  iconDelete = './assets/images/re_icon_error.svg';
  iconNotify = './assets/images/re_icon_error.svg';
  iconView = './assets/images/re_icon_error.svg';
  backArrow = './assets/images/re_icon_error.svg';
  iconClose = './assets/images/re_icon_close_popup.svg';
  forwardArrow = './assets/images/re_icon_error.svg';
  // End List Images
}
