import { Routes } from '@angular/router';
import { TokenGuard } from 'src/app/guard/token.guard';
/** Pages */
import { HomeComponent } from './pages/home/home.component';
import { SettingsComponent } from './pages/settings/settings.component';
import {UsersComponent} from './pages/users/users.component';
import {InstitutionsComponent} from './pages/institutions/institutions.component';
import {StoresComponent} from './pages/stores/stores.component';
import {ProductsComponent} from './pages/products/products.component';
import {SlidersComponent} from './pages/sliders/sliders.component';

export const AdminLayoutRoutes: Routes = [
  /* {
    path: 'users',
    component: UserHomeComponent,
    children: [
      { path: '', redirectTo: 'index', pathMatch: 'full' },
      { path: 'index', component: UserIndexComponent },
      { path: 'create', component: UserFormComponent },
      { path: 'edit/:id', component: UserFormComponent, pathMatch: 'full' }
    ]
  }, */
  /** Admin */
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [ TokenGuard ],
    children: [{
      path: '',
      loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsModule),
    }]
  },
  /** Institutions */
  {
    path: 'institutions',
    component: InstitutionsComponent,
    canActivate: [ TokenGuard ],
    children: [{
      path: '',
      loadChildren: () => import('./pages/institutions/institutions.module').then((m) => m.InstitutionsModule),
    }]
  },
  /** Stores */
  {
    path: 'stores',
    component: StoresComponent,
    canActivate: [ TokenGuard ],
    children: [{
      path: '',
      loadChildren: () => import('./pages/stores/stores.module').then((m) => m.StoresModule),
    }]
  },
  /** Products */
  {
    path: 'products',
    component: ProductsComponent,
    canActivate: [ TokenGuard ],
    children: [{
      path: '',
      loadChildren: () => import('./pages/products/products.module').then((m) => m.ProductsModule),
    }]
  },
  /** Users */
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [ TokenGuard ],
    children: [{
      path: '',
      loadChildren: () => import('./pages/users/users.module').then((m) => m.UsersModule),
    }]
  },
  /** Sliders */
  {
    path: 'sliders',
    component: SlidersComponent,
    canActivate: [ TokenGuard ],
    children: [{
      path: '',
      loadChildren: () => import('./pages/sliders/sliders.module').then((m) => m.SlidersModule),
    }]
  },
  { path: 'home', component: HomeComponent, pathMatch: 'full', canActivate: [ TokenGuard ] },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];
