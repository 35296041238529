import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatAccordion} from '@angular/material/expansion';
import { Images } from '@constants/images';

@Component({
  selector: 'app-table-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  providers: [
  ]
})

export class AccordionComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion | undefined;
  @Input() public data: string[] | null = null;
  public arrowRight = Images.arrowRight;
  @Output() itemSeleccionado = new EventEmitter();

  constructor() {

  }


  ngOnInit(): void {
    // console.log(this.data);
  }

  onclick(spec:any){
   this.itemSeleccionado.emit(spec);
  }






}
