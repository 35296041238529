<nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute fixed-top">
  <div class="container-fluid">
    <div class="icon-menu" (click)="openMenu()">
      <mat-icon class="material-icons">menu</mat-icon>
    </div>
    <div class="navbar-wrapper">
      <app-select-language></app-select-language>
    </div>
    <div class="user-info" id="navigation">
      <ul class="navbar-nav">
        <li class="nav-item">
          <mat-menu #appMenu="matMenu">
            <ng-template matMenuContent>
              <button mat-menu-item (click)="logout()">{{ 'auth.logout' | translatePipe }}</button>
            </ng-template>
          </mat-menu>
          <a href="javascript:void(0)" mat-icon-button [matMenuTriggerFor]="appMenu" class="nav-link">
            <div class="row">
              <div class="col-10">
                <p class="name">{{ user.name + ' ' + user.last_name }}</p>
                <!-- p class="rol">{{ user.userRol?.level }}</p -->
              </div>
              <div class="col-2">
                <!-- div class="image">
                  <img src="{{ user.photo }}" alt="user">
                </div -->
                <div class="arrow">
                  <img src="{{ arrowBottom }}" alt="arrow_bottom">
                </div>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
