import { Injectable } from '@angular/core';
import { CustomHttpService } from '@services/generals/customHttp.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        public customHttpService: CustomHttpService,
    ) {
    }

    public login = (userName: string, password: string) => {
        const self = this;
        const request = {
            email: userName,
            password
        };
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(true, true, 'api/cms/auth/login', request).then(
                    (response: any) => {
                        if (response === false) { resolve(response); }
                        else {
                            sessionStorage.setItem('session', JSON.stringify(response.data));
                            resolve(true);
                        }
                    }
                );
            }
        );
    }

    public getPasswordCode = (userName: string) => {
        const self = this;
        const request = {
            email: userName,
        };
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(true, true, 'api/cms/auth/getPasswordCode', request).then(
                    (response: any) => {
                        if (response === false) { resolve(response); }
                        else {
                            resolve(true);
                        }
                    }
                );
            }
        );
    }

    public passwordRecovery = (email: string, code: string, password: string, passwordRepeat: string) => {
        const self = this;
        const request = {
            email,
            code,
            password,
            repeat_password: passwordRepeat
        };
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(true, true, 'api/cms/auth/passwordRecovery', request).then(
                    (response: any) => {
                        if (response === false) { resolve(response); }
                        else {
                            resolve(true);
                        }
                    }
                );
            }
        );
    }

    public logout = () => {
        sessionStorage.removeItem('session');
    }

    public isAuthenticated = (): boolean => {
        return !!sessionStorage.getItem('session');
    }

}
