<div class="card accordion-materials-div" *ngIf="materialDates.length > 0">
  <mat-accordion class="accordion-materials" multi *ngFor="let materialDate of materialDates">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Fecha: {{ materialDate.date | date:'medium' }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-card-materials
              [materials]="materialDate.materials"
      ></app-card-materials>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div class="card accordion-materials-div" *ngIf="materialDates.length == 0">
  <h1 class="title-not-found">No hay registros</h1>
</div>
