import { Injectable } from '@angular/core';
import { CustomHttpService } from '../generals/customHttp.service';
import { AbstractService } from '../Abstract.service';
import { InstitutionClass } from '@classes/admin/institution.class';

@Injectable({ providedIn: 'root' })
export class InstitutionService extends AbstractService {
  public version = '';
  constructor(
    public customHttpService: CustomHttpService,
  ) {
    super(customHttpService, new InstitutionClass());
  }
}

