import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DataTablesModule } from 'angular-datatables';

/** Redux */
/** Copmponents */
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { ListComponent } from '@components/tables/list/list.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ButtonComponent } from '@components/button/button.component';
import { FieldFormComponent } from '@components/field_form/field_form.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormBasicComponent } from '@components/form/basic/basic.component';
import { AccordionComponent } from '@components/accordion/accordion.component';
import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { OverlayModule } from '@angular/cdk/overlay';
import { SelectLanguageComponent } from '@components/select_lang/selectLanguage.component';
import {InputSelectStoreComponent} from '@components/field_form/select-inputs/input-select-store/input-select-store.component';
import {InputSelectRoleComponent} from '@components/field_form/select-inputs/input-select-role/input-select-role.component';
import {InputSelectInstitutionComponent} from '@components/field_form/select-inputs/input-select-institution/input-select-institution.component';
import {InputImageComponent} from '@components/field_form/special-inputs/input-image/input-image.component';
import {InputKmlComponent} from '@components/field_form/special-inputs/input-kml/input-kml.component';
import {CardMaterialsComponent} from '@components/cards/card-materials/card-materials.component';
import {AccordionMaterialsComponent} from '@components/accordions/accordion-materials/accordion-materials.component';
import {InputSelectProductComponent} from '@components/field_form/select-inputs/input-select-product/input-select-product.component';
import {InputSelectSliderTypeComponent} from '@components/field_form/select-inputs/input-select-slider-type/input-select-slider-type.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PipesModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    DataTablesModule,
    MatTableModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    A11yModule,
    ClipboardModule,
    DragDropModule,
    PortalModule,
    ScrollingModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSidenavModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule
  ],
    declarations: [
        ListComponent,
        ButtonComponent,
        FieldFormComponent,
        FormBasicComponent,
        AccordionComponent,
        SelectLanguageComponent,
        InputSelectStoreComponent,
        InputSelectRoleComponent,
        InputSelectInstitutionComponent,
        InputImageComponent,
        InputKmlComponent,
        CardMaterialsComponent,
        AccordionMaterialsComponent,
        InputSelectProductComponent,
        InputSelectSliderTypeComponent,
    ],
    exports: [
        ListComponent,
        ButtonComponent,
        FieldFormComponent,
        FormBasicComponent,
        AccordionComponent,
        A11yModule,
        ClipboardModule,
        DragDropModule,
        PortalModule,
        ScrollingModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSidenavModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        OverlayModule,
        SelectLanguageComponent,
        CardMaterialsComponent,
        AccordionMaterialsComponent
    ],
  providers: [
    /** Redux-Facade */
  ]
})
export class ComponentsModule { }
