import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { reducers } from '../redux/reducers';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule} from '@angular/material/input';
import { EffectsModule } from '@ngrx/effects';
import { HttpClientModule } from '@angular/common/http';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
// Globals
import { Images } from '@constants/images';
// Components
import { ComponentsModule } from '@components/components.module';
import { SelectLanguageComponent } from '@components/select_lang/selectLanguage.component';
// Components
import { PasswordRecoveryComponent } from '@components/passwordRecovery/passwordRecovery.component';
import { NotificationComponent } from '@components/notification/notification.component';
import { LoaderComponent } from '@components/loader/loader.component';
// Modules
import { FragmentsModule } from './fragments/fragments.module';
import { PipesModule } from '@pipes/pipes.module';
import { AdminLayoutModule } from './modules/admin/admin-layout.module';
// Pages
import { AppComponent } from './app.component';
import { AuthComponent } from './modules/auth/auth.component';
import { AdminLayoutComponent } from './modules/admin/admin-layout.component';
// Redux
import { LanguageFacade } from '@redux/language.facade';
import { NotificationFacade } from '@redux/notification.facade';
import { MenuFacade } from '@redux/menu.facade';
import { LoaderFacade } from '@redux/loader.facade';
import {PoliticsComponent} from './modules/politics/politics.component';

@NgModule({
  declarations: [
    // Pages
    AppComponent,
    AuthComponent,
    AdminLayoutComponent,
    // Components
    PasswordRecoveryComponent,
    NotificationComponent,
    PoliticsComponent,
    LoaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([]),
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    HttpClientModule,
    MatMenuModule,
    MatIconModule,
    FragmentsModule,
    AdminLayoutModule,
    PipesModule,
    MatDialogModule,
    ComponentsModule
  ],
  exports: [
    MatFormFieldModule,
    MatInputModule,
    SelectLanguageComponent,
  ],
  providers: [
    Images,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'} },
    LanguageFacade,
    NotificationFacade,
    MenuFacade,
    LoaderFacade
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
