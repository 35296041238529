import { createReducer, on } from '@ngrx/store';
import * as NotificationActions from '../actions/notification.actions';
import { Notification } from '@models/notification';

export const notificationFeatureKey = 'notification';

export interface AuthPartialState {
  readonly [notificationFeatureKey]: Notification;
}

export const initialState: Notification = {
  show: false,
  success: false,
  error: false,
  title: '',
  message: ''
};

export const notificationReducer = createReducer(initialState,
  on(NotificationActions.setNotification, (state, { message }) => {
    return {
      ...state,
      show: true, success: false, error: false,
      message,
      title: 'notification.alert'
    };
  }),
  on(NotificationActions.setSuccess, (state, { message }) => {
    return {
      ...state,
      show: true, success: true, error: false,
      message,
      title: 'notification.success'
    };
  }),
  on(NotificationActions.setError, (state, { message }) => {
    return {
      ...state,
      show: true, success: false, error: true,
      message,
      title: 'notification.error'
    };
  }),
  on(NotificationActions.closeNotification, state => {
    return {
      ...state,
      show: false, success: false, error: false,
      message: '', title: ''
    };
  })
);
