import { Injectable } from '@angular/core';
import { AbstractClass, FormCell, TableCell } from '../abstract.class';
import { Store } from 'src/app/models/store';
import {Role} from 'src/app/models/role';
import {Institution} from 'src/app/models/institution';

@Injectable({providedIn: 'root'})
export class InstitutionClass extends AbstractClass{
  model: Institution;
  modelName = 'institutions';
  idName = 'id';
  langBase = 'admin.components.institutions.';
  route = 'admin/institutions';
  formAccordion = [];
  formTabs = [];
  formCells: FormCell[] = [
    { name: 'name', label: 'name', cellType: 'text', order: 1, required: true, cols: 'col-12 col-md-6', disable: false },
    { name: 'description', label: 'description', cellType: 'text', order: 2, required: true, cols: 'col-12 col-md-6', disable: false }
  ];
  tableCells: TableCell[] = [
    { name: 'name', label: 'name', cellType: 'text', order: true, download: true },
    { name: 'description', label: 'description', cellType: 'text', order: true, download: true },
    { name: 'routes_count', label: 'routes', cellType: 'number', order: true, download: true }
  ];
  stringsToRemove = ['id'];
  fieldsUnrequiredUpdate = [];

  constructor()
  {
    super();
    this.model = {
      id: 0,
      name: '',
      description: '',
      routes: []
    };
  }
}
