<div class="wrapper">
  <div class="sidebar menu-short" data-color="danger" data-background-color="white" *ngIf="!isMobileMenu">
    <app-sidebar></app-sidebar>
  </div>
  <div class="sidebar menu-short" data-color="danger" data-background-color="white" *ngIf="isMobileMenu"
       (mouseenter)="changeStateMenu(true)" (mouseleave)="changeStateMenu(false)">
    <app-sidebar></app-sidebar>
  </div>
  <div class="main-panel menu-short">
    <app-navbar></app-navbar>
    <div class="admin-content">
      <router-outlet class="div-content"></router-outlet>
    </div>
  </div>
</div>

