import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationFacade } from '@redux/notification.facade';
import { LoaderFacade } from '@redux/loader.facade';
import { environment } from '../../../environments/environment';

// Declare Vars
declare var $: any;

@Injectable()
export class UtilsService {
  private isDebug = true;


  constructor(
    private router: Router,
    private notificationFacade: NotificationFacade,
    private loaderFacade: LoaderFacade,
  ) { }

  public getUrl = (): string => {
    return environment.URL_API ?? '';
  }

  public getToken = (): string => {
    const session = sessionStorage.getItem('session');
    return session !== null ? JSON.parse(session).token : '';
  }

  public getUserID = (): string => {
    const session = sessionStorage.getItem('session') ?? '';
    return JSON.parse(session).userId ?? '';
  }

  public loadScript = (url: string) => {
    console.log('preparing to load... ' + url);
    const node = document.createElement('script');
    node.type = 'text/javascript';
    node.src = url;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  public debugConsole = (message: string) => {
    if (!environment.production) {
      console.log(message);
    }
  }

  public loader = (show: boolean = false) => {
    if (show) { this.loaderFacade.showLoader(); }
    else { this.loaderFacade.closeLoader(); }
  }

  public showMessageError = (message: string) => {
    this.notificationFacade.setError(message);
  }

  public showError = (message: any) => {
    console.log(message);
    if (message.hasOwnProperty('success')) {
      // this.debugConsole(message);
      this.showMessageError(message.error);
    } else {
      if (message.status === 401) {
        this.showMessageError('httpError.401');
        this.logout();
      } else if (message.status === 411) {
        this.showMessageError(message.error.msg);
      } else if (message.status === 400) {
        if (message.hasOwnProperty('error') && message.error !== null) {
          if (message.error.hasOwnProperty('message')) {
            this.showMessageError('backError.' + message.error.message);
          } else {
            const errorList = message.error['errors'];
            $('body').append('<div class="errorDiv"><table id="tableErrors"></table></div>');
            $('.errorDiv').click(() => { $('.errorDiv').remove(); });
            // tslint:disable-next-line:forin
            for (const err in errorList) {
              const test = errorList[err][0];
              const varError = test.indexOf('value {null}') === -1 ? errorList[err] : 'not null';
              $('#tableErrors').append('<tr><td><b class="tableErrWhite">' + err + '</b>' + varError + '</td></tr>');
            }
          }
        } else {
          this.showMessageError('backError.' + message.message);
        }
      } else if (message.status === 404) {
        this.debugConsole(message);
        if (message.error === null) {
          this.showMessageError('httpError.404');
        } else {
          this.showMessageError(message.error.message);
        }
      } else if (message.status === 503 || message.status === 500 || message.status === 0) {
        $('body').append('<div class="errorDiv"><table id="tableErrors"></table></div>');
        $('.errorDiv').click(() => { $('.errorDiv').remove(); });
        $('#tableErrors').append('<tr><td>' + message.error.msg + '</td></tr>');
        // this.alert(message.error.message);
        // alert('Error (500-503), Hemos detectado un problema de conexión por favor intenta mas tarde!');
      } else if (this.isDebug) {
        this.debugConsole(message);
        this.showMessageError(message.message);
      } else {
        this.showMessageError('Error no identificado');
      }
    }
    this.loader(false);
  }

  public logout = () => {
    sessionStorage.clear();
    this.router.navigate(['/auth']);
  }
}
