import { Injectable } from '@angular/core';
import { CustomHttpService } from '../generals/customHttp.service';
import { AbstractService } from '../Abstract.service';
import { ProductClass } from '@classes/admin/product.class';

@Injectable({ providedIn: 'root' })
export class ProductService extends AbstractService {
    public version = '';
    constructor(
        public customHttpService: CustomHttpService,
    ) {
        super(customHttpService, new ProductClass());
    }

    /** Función que inserta el Modelo */
    public create = () => {
        const self = this;
        const url = self.version + self.base;
        if (this.modelClass.model['image'] !== null && this.modelClass.model['image'].filename === undefined) {
            this.modelClass.model['image'] = '';
        }
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(
                    true,
                    true,
                    url + this.modelClass.modelName,
                    self.modelClass.getJsonBase()
                ).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        resolve(true);
                    }
                );
            }
        );
    }

    /** Funcion que actualiza el Modelo */
    async update(id: number): Promise<any> {
        const self = this;
        const url = self.version + self.base;
        if (this.modelClass.model['image'] !== null && this.modelClass.model['image'].filename === undefined) {
            this.modelClass.model['image'] = '';
        }
        return await new Promise(
            resolve => {
                self.customHttpService.httpPUT(
                    true,
                    true,
                    url + this.modelClass.modelName + '/' + id,
                    this.modelClass.getJsonUpdate()
                ).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        resolve(true);
                    }
                );
            }
        );
    }

    public getCountByID(id: number): Promise<any>{
        const self = this;
        const url = self.version + self.base + this.modelClass.modelName + '/getCountByID/' + id;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, url).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        resolve(response);
                    }
                );
            }
        );
    }

    public getExchangesByID(id: number): Promise<any>{
        const self = this;
        const url = self.version + self.base + this.modelClass.modelName + '/getExchangesByID/' + id;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, url).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        resolve(response);
                    }
                );
            }
        );
    }
}

