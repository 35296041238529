<div *ngIf="service !== null" class="my-field-form">

  <div *ngIf="cellType === 'store'">
    <app-input-select-store [serviceModel]="service" [name]="name" [label]="label" [required]="required" [multiple]="multiple"></app-input-select-store>
  </div>

  <div *ngIf="cellType === 'role'">
    <app-input-select-role [serviceModel]="service" [name]="name" [label]="label" [required]="required" [multiple]="multiple"></app-input-select-role>
  </div>

  <div *ngIf="cellType === 'institution'">
    <app-input-select-institution [serviceModel]="service" [name]="name" [label]="label" [required]="required" [multiple]="multiple" [disabled]="disable"></app-input-select-institution>
  </div>

  <div *ngIf="cellType === 'images'">
    <app-input-image [serviceModel]="service" [name]="name" [label]="label" [required]="required" [multiple]="multiple"></app-input-image>
  </div>

  <div *ngIf="cellType === 'kml'">
    <app-input-kml [serviceModel]="service" [name]="name" [label]="label" [required]="required" [multiple]="multiple"></app-input-kml>
  </div>

  <div *ngIf="cellType === 'products'">
    <app-input-select-product [serviceModel]="service" [name]="name" [label]="label" [required]="required" [multiple]="multiple"></app-input-select-product>
  </div>

  <div *ngIf="cellType === 'slider-type'">
    <app-input-select-slider-type [serviceModel]="service" [name]="name" [label]="label" [required]="required" [multiple]="multiple"></app-input-select-slider-type>
  </div>

  <mat-form-field [ngSwitch]="cellType" *ngIf="!exceptions.includes(cellType)">
    <mat-label>{{ service.modelClass.langBase + label | translatePipe }}</mat-label>
    <textarea matInput placeholder="{{ service.modelClass.langBase + label | translatePipe }}" *ngSwitchCase="'textarea'" [(ngModel)]="service.modelClass.model[name]" name="{{ name }}" [required]="required" [disabled]="disable"></textarea>

    <mat-select placeholder="{{ 'commons.forms.select' | translatePipe }}" [(ngModel)]="service.modelClass.model[name]" *ngSwitchCase="'boolean'" name="{{ name }}" [required]="required" [disabled]="disable">
      <mat-option [value]="true">Verdadero</mat-option>
      <mat-option [value]="false">Falso</mat-option>
    </mat-select>

    <mat-select placeholder="{{ 'commons.forms.select' | translatePipe }}" [(ngModel)]="service.modelClass.model[name]" *ngSwitchCase="'yesorno'" name="{{ name }}" [required]="required" [disabled]="disable">
      <mat-option [value]="true">Si</mat-option>
      <mat-option [value]="false">No</mat-option>
    </mat-select>

    <!-- ngx-mat-file-input placeholder="{{ label }}" *ngSwitchCase="'image'" (change)="selectFile($event, name)"></ngx-mat-file-input -->
    <input matInput
           [(ngModel)]="service.modelClass.model[name]"
           *ngSwitchDefault type="{{ cellType }}" name="{{ name }}" [disabled]="disable" [required]="required">
  </mat-form-field>
</div>
