import { createFeatureSelector, createSelector } from '@ngrx/store';
import { notificationFeatureKey, AuthPartialState } from '../reducers/notification.reducer';
import { Notification } from '@models/notification';

export const getNotificationState = createFeatureSelector<AuthPartialState, Notification>(
  notificationFeatureKey
);

export const selectNotificationState = createSelector(getNotificationState, state => state);

export const authQuery = {
  selectNotificationState
};
