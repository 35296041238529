<div class="div-password-recovery">
  <h1 class="title">{{ 'auth.passwordRecoveryTitle' | translatePipe }}</h1>
  <div class="div-stepper">
    <div class="step">
      <div class="active circle"></div>
      <p class="active">{{ 'auth.passwordRecoveryCode' | translatePipe }}</p>
    </div>
    <div class="step-line-div">
      <hr class="step-line {{ codeSent ? 'active' : '' }}">
    </div>
    <div class="step">
      <div class="circle {{ codeSent ? 'active' : '' }}"></div>
      <p class="{{ codeSent ? 'active' : '' }}">{{ 'auth.password' | translatePipe }}</p>
    </div>
  </div>
  <p class="text">{{ 'auth.passwordRecoveryMsg' | translatePipe }}</p>
  <form ngNativeValidate (ngSubmit)="sendCode()" class="row" *ngIf="!codeSent">
    <mat-form-field class="col-12">
      <mat-label>{{ 'auth.passwordRecoveryEmail' | translatePipe }}</mat-label>
      <input matInput placeholder="{{ 'auth.passwordRecoveryEmail' | translatePipe }}" [(ngModel)]="email" name="email" type="text" required>
    </mat-form-field>
    <div class="row div-buttons">
      <app-button class="col-6" type="button" [classCss]="'btn-cancel'" [text]="'commons.cancel'" (action)="cancel()"></app-button>
      <app-button class="col-6" type="submit" [text]="'auth.passwordRecoverySendCode'"></app-button>
    </div>
  </form>
  <form ngNativeValidate (ngSubmit)="changePassword()" class="row" *ngIf="codeSent">
    <mat-form-field class="col-12 col-sm-6 col-md-4">
      <mat-label>{{ 'auth.passwordRecoveryCode' | translatePipe }}</mat-label>
      <input matInput placeholder="{{ 'auth.passwordRecoveryCode' | translatePipe }}"
             autocomplete="off"
             [(ngModel)]="code" name="code" type="text" required>
    </mat-form-field>
    <mat-form-field class="col-12">
      <mat-label>{{ 'auth.passwordRecoveryPass' | translatePipe }}</mat-label>
      <input matInput placeholder="{{ 'auth.passwordRecoveryPass' | translatePipe }}"
             [(ngModel)]="password" name="password"
             type="{{ showPassword ? 'text' : 'password' }}"
             autocomplete="off"
             (keyup)="validatePassword()"
             (change)="validatePassword()" required>
      <p (click)="showPassword = !showPassword" class="show">{{ 'auth.showPassword' | translatePipe }}</p>
      <p class="error" *ngIf="!correctPassword">{{ 'auth.error.notCorrectPasswords' | translatePipe }}</p>
      <mat-icon aria-hidden="false" aria-label="Example home icon" class="icon-help" (click)="showHelpPassword = !showHelpPassword">help</mat-icon>
      <div class="div-help" *ngIf="showHelpPassword" (click)="showHelpPassword = !showHelpPassword">
        <h1 class="help-title">La contraseña debe contener:</h1>
        <p>
          · Una mayúscula<br>
          · Un caracter numérico<br>
          · Un caracter especial<br>
          · Mínimo 6 caracteres
        </p>
      </div>
    </mat-form-field>
    <mat-form-field class="col-12">
      <mat-label>{{ 'auth.passwordRecoveryPassRepeat' | translatePipe }}</mat-label>
      <input matInput placeholder="{{ 'auth.passwordRecoveryPassRepeat' | translatePipe }}"
             [(ngModel)]="passwordRepeat" name="passwordRepeat"
             type="{{ showPasswordRepeat ? 'text' : 'password' }}"
             autocomplete="off"
             (keyup)="validatePassword()"
             (change)="validatePassword()" required>
      <p (click)="showPasswordRepeat = !showPasswordRepeat" class="show">{{ 'auth.showPassword' | translatePipe }}</p>
      <p class="error" *ngIf="!passwordEquals">{{ 'auth.error.differentPasswords' | translatePipe }}</p>
    </mat-form-field>
    <div class="row div-buttons">
      <app-button class="col-6" type="button" [classCss]="'btn-cancel'" [text]="'commons.cancel'" (action)="goBack()"></app-button>
      <app-button class="col-6" type="submit" [text]="'commons.acceptChanges'"></app-button>
    </div>
  </form>
</div>
