import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdminLayoutRoutes } from './admin-layout.routing';
/** PIPES */
import { PipesModule } from '@pipes/pipes.module';
/** SERVICES */
import { UtilsService } from '@services/generals/utils.service';
import { CustomHttpService } from '@services/generals/customHttp.service';

/** PAGES */
import { HomeComponent } from './pages/home/home.component';

/** MODULES */
import { FragmentsModule } from '../../fragments/fragments.module';

/** COMPONENTS */
import { UsersComponent } from './pages/users/users.component';
import { InstitutionsComponent } from './pages/institutions/institutions.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { StoresComponent } from './pages/stores/stores.component';
import {ProductsComponent} from './pages/products/products.component';
import {SlidersComponent} from './pages/sliders/sliders.component';

/** EXTERNAL */

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AdminLayoutRoutes),
    PipesModule,
    FragmentsModule,
  ],
  // tslint:disable-next-line:object-literal-sort-keys
  declarations: [
    /** Pages */
    HomeComponent,
    SettingsComponent,
    UsersComponent,
    InstitutionsComponent,
    StoresComponent,
    SlidersComponent,
    ProductsComponent
  ],
  providers: [
    /** Services */
    UtilsService,
    CustomHttpService,
  ],
  exports: [],
})

export class AdminLayoutModule {}
