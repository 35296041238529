/**
 * Created by Juan Vidales Cheil on 25/4/2019.
 */
import { Pipe, PipeTransform } from '@angular/core';
import { LanguageFacade } from '@redux/language.facade';
import { take } from 'rxjs/operators';
declare var require: any;
@Pipe({ name: 'translatePipe' })
export class TranslatePipe implements PipeTransform {
  private language = 'es';

  constructor(
    public languageFacade: LanguageFacade
  ) {
    const self = this;
    this.languageFacade.languageState$.pipe(take(1)).subscribe(
      ok => {
        self.language = ok.selected;
      }
    );
  }

  transform(value: any): string {
    if (value != null) {
      const find = value.split('.');
      const language = this.language;
      const data = require('../../assets/lang/' + language + '.json');
      let count = 0;
      let dataTemp = data;
      for (const search of find) {
        count++;
        if (dataTemp.hasOwnProperty(search)) {
          if (count === find.length) {
            return dataTemp[search];
          } else {
            dataTemp = dataTemp[search];
          }
        } else {
          return value;
        }
      }
    }
    return 'N/A';
  }
}
