import { createFeatureSelector, createSelector } from '@ngrx/store';
import { loaderFeatureKey, LoaderPartialState } from '../reducers/loader.reducer';
import { Loadable } from '@models/loadable';

export const getLoaderState = createFeatureSelector<LoaderPartialState, Loadable>(
  loaderFeatureKey
);

export const selectLoaderState = createSelector(getLoaderState, state => state);

export const loaderQuery = {
  selectLoaderState
};
