import {Component, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationFacade } from '@redux/notification.facade';
import { Notification } from 'src/app/models/notification';
import { Images } from '@constants/images';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnDestroy {
  public notification: Notification = { message: '', title: '', error: false, success: false, show: false };
  public image = '';
  public className = '';
  public animation = 'show';
  // @ts-ignore
  public unsubscribeNotification: Subscription;

  constructor(
    public notificationFacade: NotificationFacade,
    public images: Images
  ) {
    const self = this;
    self.unsubscribeNotification = self.notificationFacade.notificationState$.subscribe(
      ok => {
        self.notification = ok;
        self.image = ok.error ? images.iconError : ( ok.success ? images.iconSuccess : images.iconAlert );
        self.className = ok.error ? 'error' : ( ok.success ? 'success' : 'alert' );
        if (ok.show) {
          self.closeNotification();
          self.animation = 'show';
        }
      }
    );
  }

  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    const self = this;
    self.unsubscribeNotification.unsubscribe();
  }

  closeNotification = () => {
    setTimeout( () => {
      this.animation = 'close';
    }, 3500);
    setTimeout( () => {
      this.notificationFacade.closeNotification();
    }, 4000);
  }
}
