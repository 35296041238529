import {Component, OnInit} from '@angular/core';
import { DashboardService } from '@services/cms/dashboard.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{
  public title = 'admin.components.dashboard.title';
  public overview: any = null;

  constructor(
      public dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.loadOverview();
  }

  private loadOverview = () => {
    this.dashboardService.getOverview().then((response) => {
      // console.log(response);
      this.overview = response.data;
    });
  }
}
