import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { AbstractClass, TableCell } from '@classes/abstract.class';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Images } from '@constants/images';
import { NotificationFacade } from '@redux/notification.facade';
import { round } from '@popperjs/core/lib/utils/math';
import { LoaderFacade } from '@redux/loader.facade';
import { GetJsonValue } from '@pipes/getJsonValue';
import { TranslatePipe } from '@pipes/translate';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import {Cell, Row, Workbook, Worksheet} from 'exceljs';
declare var $: any;
const customPaginatorIntl = new MatPaginatorIntl();

@Component({
  selector: 'app-table-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useValue: customPaginatorIntl }
  ]
})
export class ListComponent implements OnInit, AfterViewInit {
  @Input() public myClass: AbstractClass | null = null;
  @Input() public data: any[] | null = null;
  // ShowButtonsActions
  @Input() activeActions = true;
  @Input() showCreateAction = true;
  @Input() showEditAction = false;
  @Input() showViewAction = false;
  @Input() showPlayAndStopAction = false;
  @Input() showDeleteAction = false;
  @Input() varPlayStop = 'test';
  @Input() viewActive = false;
  // EndShowButtonsActions
  public ids: any[] = [];
  public titles: TableCell[] = [];
  displayedColumns: string[] = [];
  public imageTrash = Images.iconTrash;
  public imageEdit = Images.iconEdit;
  public imageSearch = Images.iconSearch;
  public imageLast = Images.iconPaginatorLast;
  public imageFirst = Images.iconPaginatorFirst;
  public dataSource = new MatTableDataSource<any>();
  public popUpDownload = false;
  public downloadType = 'xlsx';
  public downloadTitles: TableCell[] = [];
  // tslint:disable-next-line:max-line-length
  private alphabet: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  // Actions
  @Output() createAction = new EventEmitter<any>();
  @Output() deleteAction = new EventEmitter<any>();
  @Output() editAction = new EventEmitter<any>();
  @Output() viewAction = new EventEmitter<any>();
  @Output() changeStatusAction = new EventEmitter<any>();
  @Output() playAndStopAction = new EventEmitter<any>();
  // Paginator
  public resultsPerPage = 15;
  public page = 0;
  public lastPage = 0;
  public pagesList: number [] = [];
  public currentData: any[] = [];

  constructor(
      public images: Images,
      private notificationFacade: NotificationFacade,
      private loaderFacade: LoaderFacade,
      private changeDetectorRefs: ChangeDetectorRef,
      private getJsonValue: GetJsonValue,
      private translatePipe: TranslatePipe,
      private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    const self = this ;
    // this.displayedColumns.push('Sel');
    self.myClass?.getTablesCells().map((t) => {
      this.displayedColumns.push(t.label);
    });
    if (!this.activeActions) {
      this.displayedColumns.push('Acciones');
    }
    this.setPage(1, this.currentData = this.data ?? []);
  }

  ngAfterViewInit(): void {}

  public reloadData = (data: any[] = this.data ?? []) => {
    const self = this;
    if (data !== null) {
      const start = self.page * self.resultsPerPage;
      const end = (self.page + 1) * self.resultsPerPage;
      const dataTemp = data.slice(start, end);
      self.dataSource.data = dataTemp;
      this.changeDetectorRefs.detectChanges();
    }
  }

  public setResultsPerPage = (rpp: number) => {
    this.resultsPerPage = rpp;
    this.setPage(1, this.currentData);
  }

  public setPage = (id: number, data: any[]) => {
    this.ids = [];
    if (this.data !== null) {
      this.lastPage = round(data.length / this.resultsPerPage);
      // Adjust
      const lastPageMod = data.length % this.resultsPerPage;
      this.lastPage = lastPageMod > 0 && lastPageMod < this.resultsPerPage / 2 ? this.lastPage + 1 : this.lastPage;
      this.lastPage = this.lastPage === 0 ? 1 : this.lastPage;
      // console.log([this.lastPage, lastPageMod, this.resultsPerPage / 2]);
    }
    this.page = id - 1;
    let start = [1, 2, 3, 4, 5];
    if (this.lastPage <= 5) {
      start = [];
      for (let a = 1; a <= this.lastPage;  a++) {
        start.push(a);
      }
    }
    let end = [this.lastPage - 4, this.lastPage - 3, this.lastPage - 2, this.lastPage - 1, this.lastPage];
    if (this.lastPage <= 5) {
      end = [];
      for (let a = 1; a <= this.lastPage;  a++) {
        end.push(a);
      }
    }
    const current = [this.page - 1, this.page, this.page + 1, this.page + 2, this.page + 3];
    this.pagesList = this.page < 2 ? start : this.page > (this.lastPage - 3) ? end : current;
    this.reloadData(data);
  }

  public doFilter = ($event: any) => {
    $event.preventDefault();
    this.loaderFacade.showLoader();
    const valFilter = $('#filter').val().toString().toLowerCase();
    const title: TableCell[] = this.myClass ? this.myClass.getTablesCells() : [];
    this.currentData = this.data?.filter((d => {
      let find = false;
      const value: any = d;
      title.forEach((m) => {
        const text = this.getJsonValue.transform(d, m.name);
        if (!find && m.cellType === 'exchange' && text !== null) {
          if ('canjeado'.startsWith(valFilter) && text) {
            find = true;
          } else if ('no canjeado'.startsWith(valFilter) && !text) {
            find = true;
          }
        } else if (!find && m.cellType === 'active-text' && text !== null) {
          if ('activo'.startsWith(valFilter) && text) {
            find = true;
          } else if ('inactivo'.startsWith(valFilter) && !text) {
            find = true;
          }
        } else if (!find && m.cellType === 'date' && text !== null) {
          const tempText = text.replace('-','/').replace('-','/');
          find = tempText.toString().toLowerCase().includes(valFilter);
        } else if (!find && m.cellType !== 'active' && text !== null) {
          find = text.toString().toLowerCase().includes(valFilter);
        }
      });
      return find;
    })) ?? [];
    this.setPage(1, this.currentData);
    this.loaderFacade.closeLoader();
  }

  public updateSelect = (id: any) => {
    if (this.ids.includes(id)) {
      this.ids = this.ids.filter(i => i !== id);
    } else {
      this.ids.push(id);
    }
  }

  public idChecked = (id: any): boolean => {
    return this.ids.filter(t => t === id).length > 0;
  }

  public updateDownloadTitles = (title: TableCell) => {
    if (this.downloadTitles.includes(title)) {
      this.downloadTitles = this.downloadTitles.filter(t => t.name !== title.name);
    } else {
      this.downloadTitles.push(title);
    }
  }

  public create = () => {
    this.ids = [];
    this.createAction.emit();
  }

  public sendData = (type: string) => {
    const self = this;
    const id = self.myClass?.idName ?? '';
    const dataTemp = self.data?.filter( d => self.ids.includes(d[id]) ) ?? [];
    switch (type) {
      case 'edit':
        this.editAction.emit(dataTemp);
        break;
      case 'delete':
        this.deleteAction.emit(dataTemp);
        break;
      case 'view':
        this.viewAction.emit(dataTemp);
        break;
      case 'one':
        this.playAndStopAction.emit(dataTemp);
        break;
    }
  }

  public sendSingleData = (id: any, type: string) => {
    this.ids = [id];
    this.sendData(type);
  }

  public changeStatus = (name: string, data: AbstractClass) => {
    if (this.myClass?.model) {
      const dataTemp: any = data;
      if (typeof this.myClass.model[name] === 'boolean') {
        dataTemp[name] = !dataTemp[name];
      } else if (typeof this.myClass.model[name] === 'number') {
        dataTemp[name] = dataTemp[name] !== 1 ? 1 : 0;
      }
      this.changeStatusAction.emit(dataTemp);
    }
  }

  public download = () => {
    const title = this.myClass ? this.myClass.modelName : 'Results';
    /*** Header **/
    const header: string[] = [];
    this.downloadTitles.map((t: TableCell) => {
      const name = this.translatePipe.transform(this.myClass?.langBase + t.label);
      header.push(name);
    });
    const workbook = new Workbook();
    const worksheet: Worksheet = workbook.addWorksheet(title);
    // Add Header Row
    const headerRow = worksheet.addRow(header);
    headerRow.height = 20;
    // Cell Style : Fill and Border
    headerRow.eachCell((cell: Cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF10375C' },
        bgColor: { argb: 'FF10375C' }
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
      cell.font = {
        color: { argb: 'FFFFFFFF' },
        size: 14,
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    // Data
    const data: any[] = [];
    this.currentData.map((d: any) => {
      const temp: any = [];
      let height = 16;
      this.downloadTitles.map((t: TableCell) => {
        const value = this.getJsonValue.transform(d, t.name);
        if (t.cellType === 'active' || t.cellType === 'active-text') {
          if (value.toString() === '1' || value.toString() === 'true') {
            temp.push('activo');
          } else {
            temp.push('inactivo');
          }
        } else if (t.cellType === 'date') {
          temp.push(this.datePipe.transform(value, 'y/MM/dd'));
        } else if (t.cellType === 'image') {
          height = 120;
          temp.push(this.datePipe.transform(value, 'y/MM/dd'));
        } else {
          temp.push(value);
        }
      });
      // Add row to Excel
      const row = worksheet.addRow(temp);
      row.height = height;
      row.eachCell((c: Cell) => {
        c.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        c.font = {
          size: 14,
        };
      });
      data.push(temp);
    });

    // Set Width in all Columns
    for (let a = 1; a <= header.length; a++) {
      worksheet.getColumn(a).width = 22.29;
    }
    // Generate Excel File with given name
    if (this.downloadType === 'xlsx') {
      workbook.xlsx.writeBuffer().then(
          (d: BlobPart) => {
            const blob = new Blob([d], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, title + '.' + this.downloadType);
          });
    } else {
      workbook.csv.writeBuffer().then(
          (d: BlobPart) => {
            const blob = new Blob([d], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, title + '.' + this.downloadType);
          });
    }
  }

  public changeDownloadType = (type: string) => this.downloadType = type;
}
