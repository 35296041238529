import { Injectable } from '@angular/core';
import { AbstractClass, FormCell, TableCell } from '../abstract.class';
import { Product } from 'src/app/models/product';

@Injectable({providedIn: 'root'})
export class ProductClass extends AbstractClass{
  model: Product;
  modelName = 'products';
  idName = 'id';
  langBase = 'admin.components.product.';
  route = 'admin/products';
  formAccordion = [];
  formTabs = [];
  formCells: FormCell[] = [
    { name: 'name', label: 'name', cellType: 'text', order: 1, required: true, cols: 'col-12 col-md-6', disable: false },
    { name: 'description', label: 'description', cellType: 'textarea', order: 2, required: true, cols: 'col-12 col-md-6', disable: false },
    { name: 'points', label: 'points', cellType: 'number', order: 3, required: true, cols: 'col-12 col-md-6', disable: false },
    { name: 'have_promotion', label: 'have_promotion', cellType: 'yesorno', order: 4, required: true, cols: 'col-12 col-md-6', disable: false },
    { name: 'promotion_points', label: 'promotion_points', cellType: 'number', order: 5, required: true, cols: 'col-12 col-md-6', disable: false },
    { name: 'isActive', label: 'isActive', cellType: 'boolean', order: 6, required: true, cols: 'col-12 col-md-6', disable: false },
    { name: 'image', label: 'image', cellType: 'images', order: 7, required: true, cols: 'col-12 col-md-6', disable: false }
  ];
  tableCells: TableCell[] = [
    { name: 'image', label: 'image', cellType: 'image', order: true, download: true },
    { name: 'name', label: 'name', cellType: 'text', order: true, download: true },
    { name: 'description', label: 'description', cellType: 'text', order: true, download: true },
    { name: 'points', label: 'points', cellType: 'number', order: true, download: true },
    { name: 'have_promotion', label: 'have_promotion', cellType: 'yesorno', order: true, download: true },
    { name: 'promotion_points', label: 'promotion_points', cellType: 'number', order: true, download: true },
    { name: 'isActive', label: 'isActive', cellType: 'active-text', order: true, download: true },
    { name: 'created_at', label: 'created_at', cellType: 'date', order: true, download: true },
    { name: 'updated_at', label: 'updated_at', cellType: 'date', order: true, download: true }
  ];
  stringsToRemove = ['id'];
  fieldsUnrequiredUpdate = [];

  constructor()
  {
    super();
    this.model = {
      id: 0,
      name: '',
      description: '',
      image: '',
      isActive: true,
      points: 0,
      have_promotion: false,
      promotion_points: 0,
      country_id: 1,
      created_at: new Date(),
      updated_at: new Date()
    };
  }
}
