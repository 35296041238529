<div class="admin-content-header">
  <h1 class="admin-content-title">{{ title | translatePipe }}</h1>
</div>
<hr>
<div class="dashboard">
  <div class="overview" *ngIf="overview !== null">
    <div class="card">
      <div class="card-header">{{ 'Tipos de Usuarios' }}</div>
      <div class="card-body">
        <div class="row users-div">
          <div class="col-12 user" *ngFor="let rol of overview.users.roles">
            {{ rol.name }}: <span>{{ rol.count }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">Estadisticas Generales</div>
      <div class="card-body">
        <div class="row users-div">
          <div class="col-12 info">
            {{ 'Tiendas' }}: <span>{{ overview.stores.count }}</span>
          </div>
          <div class="col-12 info">
            {{ 'Centros de Acopio' }}: <span>{{ overview.institutions.count }}</span>
          </div>
          <div class="col-12 info">
            {{ 'Rutas de recolección' }}: <span>{{ overview.recyclingRoutes.count }}</span>
          </div>
          <div class="col-12 info">
            {{ 'Productos' }}: <span>{{ overview.products.count }}</span>
          </div>
          <div class="col-12 info">
            {{ 'Recolección de material en centro de acopio' }}: <span>{{ overview.deliveries.count }}</span>
          </div>
          <div class="col-12 info">
            {{ 'Entregas de material por ciudadanos' }}: <span>{{ overview.exchanges.count }}</span>
          </div>
          <div class="col-12 info">
            {{ 'Solicitudes / Redenciones de productos' }}: <span>{{ overview.exchanges.requests }} / {{ overview.exchanges.redemptions }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
