import {createFeatureSelector, createSelector} from '@ngrx/store';
import {Menu, menuFeatureKey, MenuPartialState} from '../reducers/menu.reducer';

export const getMenuState = createFeatureSelector<MenuPartialState, Menu>(
  menuFeatureKey
);

export const selectMenuState = createSelector(getMenuState, state => state);

export const menuQuery = {
  selectMenuState
};
