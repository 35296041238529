import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatAccordion} from '@angular/material/expansion';
import { Images } from '@constants/images';
import {Materials} from 'src/app/models/materials';

@Component({
  selector: 'app-card-materials',
  templateUrl: './card-materials.component.html',
  styleUrls: ['./card-materials.component.scss'],
  providers: [
  ]
})

export class CardMaterialsComponent implements OnInit {
  @Input() public materials: Materials | null = null;

  constructor() {}

  ngOnInit(): void {}
}
