import { Component, Input, OnInit } from '@angular/core';
import { AbstractService } from '@services/Abstract.service';
@Component({
  selector: 'app-field-form',
  templateUrl: './field_form.component.html',
  styleUrls: ['./field_form.component.scss']
})

export class FieldFormComponent implements OnInit {
  @Input() public service: AbstractService | null = null;
  @Input() label = '';
  @Input() cellType = '';
  @Input() name = '';
  @Input() disable = false;
  @Input() required = false;
  @Input() multiple = false;
  ckeConfig: any;
  public exceptions = ['active', 'store', 'role', 'institution', 'images', 'kml', 'products',
    'slider-type'];

  constructor() {}

  ngOnInit(): void {
    const self = this;
    self.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true
    };
  }

  public selectFile = ($event: any, name: string) => {
    if (this.service !== null) { this.service.modelClass.model[name] = this.getImageBase64($event); }
  }

  /**
   * Obtiene los datos de la imagen y la codifica en base64
   */
  private getImageBase64 = (image: any) => {
    const imageBase64 = {
      height: 0,
      timestamp: '',
      uri: '',
      fileName: '',
      data: ''
    };
    const file: File = image.target.files[0];
    const myDate = new Date();
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      imageBase64.height = file.size;
      imageBase64.timestamp = '2019-01-06T17:16:40';
      imageBase64.uri = file.name;
      imageBase64.fileName = file.name;
      const result = myReader.result ?? '';
      imageBase64.data = result.toString().split(',')[1] ?? '';
    };
    myReader.readAsDataURL(file);
    return imageBase64;
  }
}
