import {Component, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import { MenuFacade } from '@redux/menu.facade';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnDestroy {
  public menuPermanent = false;
  // @ts-ignore
  public unsubscribeMenu: Subscription;

  constructor(
    private router: Router,
    public menuFacade: MenuFacade
  ) {
    const self = this;
    self.unsubscribeMenu = self.menuFacade.menuState$.subscribe(
      ok => {
        self.menuPermanent = ok.permanent;
        $('.main-panel').removeClass(!self.menuPermanent ? 'menu-permanent' : '');
        $('.main-panel').addClass(self.menuPermanent ? 'menu-permanent' : '');
      }
    );
  }

  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    const self = this;
    self.unsubscribeMenu.unsubscribe();
  }

  public isMobileMenu = () => {
    return $(window).width() <= 991;
  }

  public changeStateMenu = (open: boolean) => {
    if (!this.menuPermanent) {
      this.menuFacade.setOpen(open);
    }
  }
}
