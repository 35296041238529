import { Component, OnDestroy, OnInit } from '@angular/core';
import { Images } from '@constants/images';
import { Subscription } from 'rxjs';
import { MenuFacade } from '@redux/menu.facade';
declare var $: any;

declare interface SubRouteInfo {
  path: string;
  title: string;
}

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  rol: number;
  open: boolean;
  submenu: SubRouteInfo[];
}
export const ROUTES: RouteInfo[] = [
  { path: '/admin/home', title: 'menu.home',  icon: Images.iconHome, class: '', rol: 1, open: false, submenu: [] },
  { path: '/admin/sliders', title: 'menu.sliders',  icon: Images.iconUsers, class: '', rol: 1, open: false, submenu: [] },
  { path: '/admin/institutions', title: 'menu.institutions',  icon: Images.iconUsers, class: '', rol: 1, open: false, submenu: [] },
  { path: '/admin/stores', title: 'menu.stores',  icon: Images.iconUsers, class: '', rol: 1, open: false, submenu: [] },
  { path: '/admin/products', title: 'menu.products',  icon: Images.iconUsers, class: '', rol: 1, open: false, submenu: [] },
  { path: '/admin/users', title: 'menu.users',  icon: Images.iconUsers, class: '', rol: 1, open: false, submenu: [] },
  { path: '/admin/settings/logs', title: 'menu.logs',  icon: Images.iconDeviceSettings, class: '', rol: 1, open: false, submenu: [] },
    /*
  { path: '/admin/settings', title: 'menu.settings',  icon: Images.iconGeneralSettings, class: '', rol: 1, open: false, submenu: [
      { path: '/admin/settings/countries', title: 'menu.country' },
      // { path: '/admin/properties/inventory', title: 'menu.inventory' },
      // { path: '/admin/properties/prices', title: 'menu.prices' },
      // { path: '/admin/properties/flooring', title: 'menu.flooring' }
  ] }
  /* { path: '/admin/marketData', title: 'menu.marketData',  icon: Images.iconMarketData, class: '', rol: 1, open: false, submenu: [
      { path: '/admin/marketData/sell-out', title: 'menu.sellOut' },
      { path: '/admin/marketData/inventory', title: 'menu.inventory' },
      { path: '/admin/marketData/prices', title: 'menu.prices' },
      { path: '/admin/marketData/flooring', title: 'menu.flooring' }
    ] }, */
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  public menuItems: any[] = [];
  public menuLarge = false;
  public menuPermanent = false;
  // @ts-ignore
  public unsubscribeMenu: Subscription;

  constructor(
    public menuFacade: MenuFacade
  ) {
    const self = this;
    self.unsubscribeMenu = self.menuFacade.menuState$.subscribe(
      ok => {
        self.menuLarge = ok.open;
        self.menuPermanent = ok.permanent;
        $('.sidebar').removeClass(!self.menuLarge ? 'menu-large' : 'menu-short');
        $('.sidebar').addClass(self.menuLarge ? 'menu-large' : 'menu-short');
        $('.main-panel').removeClass(!self.menuLarge ? 'menu-large' : 'menu-short');
        $('.main-panel').addClass(self.menuLarge ? 'menu-large' : 'menu-short');
      }
    );
  }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem.rol === 1);
  }

  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    const self = this;
    self.unsubscribeMenu.unsubscribe();
  }

  public isMobileMenu = () => {
    return $(window).width() <= 991;
  }

  public openSubmenu = (menuItem: RouteInfo) => {
    this.menuItems.map((value) => {
      if (value.path === menuItem.path) {
        menuItem.open = !menuItem.open;
      } else {
        value.open = false;
      }
    });
  }

  public changePermanent = (permanent: boolean) => {
    this.menuFacade.setPermanent(permanent);
  }

  public closeMenu = () => {
    this.menuFacade.setOpen(false);
  }
}
