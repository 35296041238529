import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'getJsonValue'})
export class GetJsonValue implements PipeTransform {
  transform(json: any, find: string): string {
    const splitValues = find.split('.');
    let text: any = json;
    splitValues.forEach((value, index) => {
      text = text === null ? 'JSON N/F' : text.hasOwnProperty(value) ? text[value] : 'JSON N/F';
    });
    text = text === undefined || text === 'JSON N/F' ? '-' : text;
    return text;
  }
}
