import { Component } from '@angular/core';
import { take } from 'rxjs/operators';
import { LanguageFacade } from '@redux/language.facade';

@Component({
  selector: 'app-select-language',
  templateUrl: './selectLanguage.component.html',
  styleUrls: ['./selectLanguage.component.scss']
})
export class SelectLanguageComponent {
  public languages: string[] = [];
  public language = 'es';

  constructor(
    public languageFacade: LanguageFacade
  ) {
    const self = this;
    this.languageFacade.languageState$.pipe(take(1)).subscribe(
      ok => { self.languages = ok.languages; }
    );
  }

  public changeLang = () => {
    this.languageFacade.setLanguage(this.language);
  }
}
