<div class="recycling-route-material-list" *ngIf="materials !== null">
  <p>Archivo papel<b>{{ materials.papper }} Kg</b></p>
  <p>Cartón<b>{{ materials.paperboard }} Kg</b></p>
  <p>Chatarra<b>{{ materials.scrap }} Kg</b></p>
  <p>Aluminio / otros metales <b>{{ materials.metals }} Kg</b></p>
  <p>PASTA (plastico de alta densidad)<b>{{ materials.high_density_plastic }} Kg</b></p>
  <p>PET<b>{{ materials.pet }} Kg</b></p>
  <p>Plastico de baja densidad<b>{{ materials.low_density_plastic }} Kg</b></p>
  <p>Vidrio<b>{{ materials.glass }} Kg</b></p>
  <p>Otros<b>{{ materials.others }} Kg</b></p>
</div>

